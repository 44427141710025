$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
        0: 0,
        15: ($spacer * 1.5),
        1: ($spacer * 1),
        2: ($spacer * 2),
        3: ($spacer * 3),
        4: ($spacer * 4),
        5: ($spacer * 5),
        6: ($spacer * 6),
        7: ($spacer * 7),
        8: ($spacer * 8),
        9: ($spacer * 9),
        10: ($spacer * 10)
), $spacers);

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

// Some special margin utils
.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}
