.modal {
  .modal-dialog {
    max-width: 90rem;
    width: 80%;
  }

  .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal-header {
    background: $bg-color;
    border-bottom-color: $color-lightblue;

    .modal-title {
      color: $color-primary;
      font-size: 4.2rem;
      text-align: center;
      text-transform: none;
    }

    .close {
      color: $color-secondary;
      font-size: 3rem;
      opacity: 1;
      padding: 1.5rem;
      transition: all .3s ease-in-out;
      border-radius: 3rem;

      &:hover {
        color: $color-white;
        background: $color-secondary;
        text-shadow: 0 2px 0 darken($color-secondary, 5%);
      }
    }
  }
}

.modal.modal-survey-result {
  .modal-subheader {
    background: $bg-color;
    border-bottom: 1px solid lighten($color-lightblue, 3%);
    color: $color-grey;
    font-size: 1.5rem;
    padding: 1.5rem;

    img {
      display: inline-block;
      height: 3.2rem;
    }

    span {
      display: inline-block;
      line-height: 3.2rem;

      strong {
        font-weight: $font-bold;
        margin-left: 1rem;
      }
    }

    .col-xs-6:first-child {
      text-align: right;
    }
  }

  .modal-body {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}



.iziModal {
  .iziModal-header {
    background: $color-aqua !important;
    box-shadow: none !important;
    &.iziModal-noSubtitle .iziModal-header-title {
      font-size: 1.6rem !important;
      text-transform: uppercase;
    }
  }
}
