.navbar-brand {
  color: $color-primary;
  display: block;
  height: auto;
  float: none;
  font-size: 2.5rem;
  line-height: 1.15;
  padding: 0;

  span {
    color: $color-secondary;
    display: block;
    font-size: 2rem;
    font-weight: $font-bold;
  }

  em {
    font-size: 1.4rem;
    font-style: normal;
    display: block;
    margin-top: .5rem;
  }
}
