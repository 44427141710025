.user-card {
  margin-top: 3rem;
  margin-bottom: 8rem;
  text-align: center;

  .user-name {
    color: $color-white;
    font-size: 1.6rem;
    margin-top: 1rem;
  }

  .user-type {
    color: $color-white;
    font-size: 1.3rem;
    font-weight: $font-bold;
    text-transform: uppercase;
    display: block;

    span {
      color: $color-lightblue;
      opacity: .8;
      text-transform: none;
      font-weight: normal;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 57px;
    }
  }

  .user-logout {
    color: $color-white;
    margin-top: 1rem;
    font-size: 1.6rem;
    font-weight: $font-bold;
    display: inline-block;
    text-transform: uppercase;
    padding: .5rem 1rem;
    border-radius: 12rem;
    transition: background .3s ease-in-out;

    &:hover,
    &:focus {
      text-decoration: none;
      background: transparentize($color-petrol, 0.5);
    }
  }
}
