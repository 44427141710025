button {
  outline: none !important;
}

.btn {
  padding: 0.9rem 1.6rem;
  height: 4rem;
  margin: 0;
  position: relative;
  border-radius: 2rem;
  line-height: 2rem;
  white-space: nowrap;
  font-size: 1.6rem;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  outline: none !important;
  border: 1px solid transparent;
  transition: color .3s ease-in-out, border-color .3s ease-in-out, background .3s ease-in-out;

  &:hover,
  &:focus,
  &:focus:hover,
  &:active,
  &.active {
    text-decoration: none;
    box-shadow: none;
    border-color: transparent;
  }

  &[disabled],
  &[disabled]:hover,
  &.disabled,
  &.disabled:hover {
    opacity: 0.5;
    cursor: not-allowed;
  }

  i[class*="ojm-"] {
    margin-right: 0.4rem;
    &:before {
      position: relative;
      top: 1px;
    }
  }
}

.btn.btn-default {
  background: $color-white !important;
}

.btn.btn-primary {
  font-weight: $font-bold;
  text-transform: uppercase;

  background: $color-aqua;
  border-color: $color-aqua;
  color: $color-white;

  &:hover,
  &:focus,
  &:focus:hover,
  &:active,
  &.active {
    background: $color-petrol;
    border-color: $color-petrol;
    color: $color-white;
  }

  &[disabled]:hover,
  &.disabled:hover {
    background: $color-aqua;
  }

  &.loading {
    color: transparentize($color-white, .5);
  }
}

.btn.btn-primary-outline {
  font-weight: $font-bold;
  text-transform: uppercase;

  background: $color-white;
  border-color: $color-aqua;
  color: $color-aqua;

  &.big-border{
    border-size: 2px;
  }

  &:hover,
  &:focus,
  &:focus:hover,
  &:active,
  &.active {
    background: $color-petrol;
    border-color: $color-petrol;
    color: $color-white;
  }

  &[disabled]:hover,
  &.disabled:hover {
    background: $color-aqua;
  }

  &.loading {
    color: transparentize($color-white, .5);
  }
}

.btn.btn-secondary {
  font-weight: $font-bold;
  text-transform: uppercase;

  background: $color-white;
  border-color: $color-aqua;
  color: $color-aqua;

  &:hover,
  &:focus,
  &:focus:hover,
  &:active,
  &.active {
    background: $color-white;
    border-color: $color-petrol;
    color: $color-petrol;
  }

  &[disabled]:hover,
  &.disabled:hover {
    background: $color-white;
  }

  &.loading {
    color: transparentize($color-aqua, .5);
  }
}

.btn-tertiary {
  font-weight: $font-semibold;

  background: $color-white;
  border-color: $color-steel;
  color: $color-grey;

  &:hover,
  &:focus,
  &:focus:hover,
  &:active,
  &.active {
    background: $color-white;
    border-color: $color-grey;
    color: $color-anthracite;
  }

  &[disabled]:hover,
  &.disabled:hover {
    background: $color-white;
  }

  &.loading {
    color: transparentize($color-grey, .5);
  }
}

.btn-transparent {
  font-weight: $font-bold;
  text-transform: uppercase;

  background: transparent;
  border-color: transparent;
  color: $color-aqua;

  &:hover,
  &:focus,
  &:focus:hover,
  &:active,
  &.active {
    background: transparent;
    border-color: transparent;
    color: $color-petrol;
  }

  &[disabled]:hover,
  &.disabled:hover {
    background: transparent;
  }
}

.btn-link {
  font-weight: $font-semibold;

  background: transparent;
  border-color: transparent;
  color: $color-aqua;

  &:hover,
  &:focus,
  &:focus:hover,
  &:active,
  &.active {
    background: transparent;
    border-color: transparent;
    color: $color-petrol;
  }

  &[disabled]:hover,
  &.disabled:hover {
    background: transparent;
  }
}

.btn-red {
  font-weight: $font-bold;
  text-transform: uppercase;

  background: $color-red;
  border-color: $color-red;
  color: $color-white;

  &:hover,
  &:focus,
  &:focus:hover,
  &:active,
  &.active {
    background: darken($color-red, 7);
    border-color: darken($color-red, 7);
    color: $color-white;
  }

  &[disabled]:hover,
  &.disabled:hover {
    background: $color-red;
  }
}

// full button
.btn.btn-full {
  display: block;
  width: 100%;
}

// remove horizontal padding
.btn.btn-no-p-x {
  padding-left: 0;
  padding-right: 0;
}

.btn-icon {
  width: 4.2rem;
  height: 4.2rem;
  text-align: center;
  padding: 0.9rem;
  background: $color-white;
  border-color: $color-lightblue;
  color: $color-ojm;
  border-radius: 50%;

  i[class*="ojm-"] {
    margin: 0 !important;
  }

  &:hover,
  &:focus,
  &:focus:hover,
  &:active,
  &.active {
    color: $color-white;
    background: $color-aqua;
    border-color: $color-aqua;
  }

  &[disabled]:hover,
  &.disabled:hover {
    background: $color-white;
  }

}


button.no-style {
  border: none;
  background: none;
  padding: 0;
  color: $color-aqua;

  &:hover {
    color: $color-petrol;
  }
}
