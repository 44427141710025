aside + main.content {
  margin-left: 24rem;
  min-height: 100vh;
  padding-bottom: 8.8rem;
  position: relative;

  #footer {
    border-top: 1px solid $color-lightblue;
    padding-top: 3rem;
    padding-bottom: 3rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}


.custom-container {
  margin-left: 0;
  width: 100%;
  @media screen and (min-width: $screen-md-min) {
    padding-left: 6rem;
    max-width: 692px;
  }
  @media screen and (min-width: $screen-lg-min) {
    padding-left: 8rem;
    max-width: 880px;
  }
  @media screen and (min-width: 1408px) {
    padding-left: 8rem;
    max-width: 1080px;
  }
  @media screen and (min-width: 1408px) {
    .col-xl-4 {
      width: 33.3333%
    }
  }
}
