.device-obscurator {
  display: none !important;
  background: $bg-color;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;

  .panel {
    background: $color-white;
    border-radius: 12px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    .mobile, .tablet {
      padding: 4.5rem 4rem;
    }

    img {
      margin-bottom: 3rem;
      max-width: 85px;
    }

    .title {
      color: $color-primary;
      font-size: 2.5rem;
      font-weight: $font-light;

      strong {
        font-weight: $font-bold;
      }
    }
  }

  .tablet {
    display: none;
    padding: 8rem 7.5rem;

    img {
      max-width: 100px;
    }

    .title {
      font-size: 4.2rem;
    }
  }

  .header {
    padding: 3rem;
  }

  .footer {
    border-top: 1px solid $color-lightblue;
    padding: 3rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      height: 3rem;
      margin-bottom: 1rem;
    }

    .copy {
      color: $color-grey;
      font-size: 1.3rem;
    }
  }
}

@media screen and (max-width: $screen-sm-max) {
  body {
    overflow: hidden;
  }
  .device-obscurator {
    display: block !important;
  }
}

@media screen and (min-width: $screen-sm-min) {
  .device-obscurator {
    .mobile {
      display: none;
    }

    .tablet {
      display: block;
    }
  }
}
