.dropdown--create-quote {
  .dropdown-menu {
    background: white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 1rem 0;
    min-width: 20rem;
    margin-top: .7rem;
    border-color: $color-lightblue;
    border-radius: 0;
    & > li > a,  & > li > div {
      padding: .9rem 1rem;
      color: $color-black;
      i {
        font-size: 1.8rem;
        vertical-align: text-top;
        margin-right: .5rem;
      }
      &:hover, &:focus {
        background-color: $bg-color;
      }
      .counter {
        background: $color-aqua;
        color: $color-white;
        font-size: 1rem;
        display: inline-block;
        padding: .2rem .6rem;
        border-radius: .9rem;
        margin-left: auto;
        letter-spacing: 1px;
      }
    }
  }
}

.header-flex {
  .open > .dropdown-toggle, .dropdown-toggle:hover {
    background: $color-petrol !important;
    border-color: $color-petrol !important;
  }
}
