table.table {
  font-size: 1.4rem;
  img {
    height: 3.2rem;
    min-width: 3.2rem;
  }

  tr td {
    background: $color-white;
    padding: 1rem;
    vertical-align: middle;

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 2rem;
    }
  }

  td.fit-to-content {
    white-space: nowrap;
    width: 1%;
  }

  .btn.btn-transparent {
    font-size: 1.4rem;
  }
}

.dataTable-simple {
  .table-head {
    margin-bottom: 1.5rem;
  }
  table.dataTable.display {
    border-bottom: none;

    thead {
      th {
        background-size: 12px auto;
        background-position: 5px 18px;
        font-size: 1.2rem;
        line-height: 1.47;
        font-weight: normal;
        color: $color-anthracite;
        &:focus {
          outline: none;
        }
      }
    }

    thead th,
    thead td {
      padding: 1.3rem 2rem;
      border-bottom: none;
    }

    tbody tr {
      background-color: $color-white;
      border-bottom: none;

      &.odd {
        background-color: $color-white;
      }

      td {
        padding: 1.3rem 2rem 1.5rem;
        margin-bottom: 5px;

        &.sorting_1 {
          background-color: #f9f9f9 !important;
        }
      }
    }

    .btn-col {
      text-align: right;

      a {
        color: $color-primary;
        font-size: 1.8rem;
        margin-left: 1rem;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
          color: $color-secondary;
        }
      }
    }
  }

  .dataTables_wrapper {
    padding-bottom: 6rem;
    .dataTables_info,
    .dataTables_paginate {
      margin-top: 3rem;
      font-size: 1.2rem;
    }

    .dataTables_info {

      line-height: 1.47;
      color: $color-anthracite;
    }

    .dataTables_empty {
      text-align: center !important;
      font-weight: normal !important;
      font-style: italic;
    }

    .dataTables_paginate {
      .paginate_button {
        color: $color-anthracite;

        &.previous,
        &.next {
          color: $color-secondary !important;
          border: none !important;
          outline: none;
          box-shadow: none;

          &.disabled, &.disabled:hover {
            color: $color-grey !important;
            opacity: .8;
          }

          &:hover,
          &:focus,
          &.active {
            background: none !important;
            border: none;
            color: $color-primary !important;
          }
        }

        &.previous {
          padding-left: 0;
        }

        &.next {
          padding-right: 0;
        }
      }

      span .paginate_button {
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 100px;
        line-height: 2.6rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none !important;
        outline: none;
        box-shadow: none;

        &:hover,
        &:focus,
        &.active {
          background: none;
          color: $color-secondary !important;
        }

        &.current {
          background: $color-secondary;
          color: $color-white !important;
          font-weight: bold;
        }
      }
    }

    .dataTables_filter {
      float: initial;
      text-align: left;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      label {
        font-weight: normal;
        font-size: 0;
        width: 100%;
        position: relative;
        &:before {
          display: block;
          content: $ojm-search;
          font-family: "ojm-docflow";
          font-weight: normal;
          vertical-align: top;
          font-size: 2rem;
          color: $color-primary;
          position: absolute;
          left: 15px;
          top: 5px;
        }
      }

      input {
        border: 1px solid $input-border;
        border-radius: .6rem;
        padding: .9rem 1.2rem .9rem 4.5rem;
        margin: 0;
        width: 50%;
        box-shadow: none;
        background: $color-white;
        font-size: 1.6rem;
        height: 4rem;
        color: $color-black;
        transition: border-color linear 0.15s, box-shadow linear 0.06s;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder,
        &::placeholder {
          color: $color-grey;
          font-style: italic;
          font-weight: $font-light;
        }

        &:hover {
          border-color: darken($input-border, 18%);
        }

        &:focus {
          border-color: $color-ojm;
          box-shadow: 0 0 0 1px $color-ojm;
        }
      }
    }
  }
}


.table-content {
  position: relative;

  .dataTables_processing {
    background: $color-lightblue;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: initial;
    margin: 0;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: transparent;
    opacity: .7;
  }
}

.custom-dataTables table.dataTable {
  tbody tr {
    &:hover {
      cursor: pointer;

      td,
      td[class^="sorting"] {
        background: transparentize($color-lightblue, .7) !important;
      }
    }

    &.child {
      td {
        text-align: left;
        font-weight: normal;
        padding: 1rem;

        ul {
          padding-left: 1rem;

          li:last-child {
            text-align: right;
            margin-top: 2rem;

            .dtr-title {
              display: none;
            }
          }
        }
      }
    }
  }

  td {
    &:last-child {
      text-align: right;
      padding-right: 1.8rem;
    }

    &:first-child {
      padding-left: 1.8rem;
    }

    &:nth-child(1),
    &:nth-child(2) {
      font-weight: $font-semibold;
    }

    a {
      text-decoration: none;
      font-weight: $font-bold;
      padding: 0.5rem;
      white-space: nowrap;

      i {
        font-size: 2rem;
        position: relative;
        top: 3px;
        margin-left: 6px;
      }
    }
  }
}
