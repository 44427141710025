html {
  font-size: 62.5%;
  height: 100%;
}

body {
  background:  $color-white;
  color:       $color-black;
  font-family: $font-family-base !important;
  font-size:   1.5rem;
  line-height: 1.5 !important;
  min-height:  100%;
  margin:      0 !important;

  &.body--grey {
    background: $bg-color;
  }
}

a {
  color: $color-aqua;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: $color-petrol;
  }
}

p,
ol,
ul {
  font-size: 1.5rem;
}

h1, .h1 {
  color: $color-ojm;
  font-size: 3rem;
  font-weight: $font-light;

  span {
    color: $color-aqua;
    font-weight: $font-semibold;
  }
}

h2, .h2 {
  color: $color-ojm;
  font-size: 2rem;
  margin: 0 0 2rem 0;
  font-weight: $font-semibold;
}

h3, .h3,
h4, .h4 {
  color: $color-anthracite;
  font-size: 1.4rem;
  font-weight: $font-light;
  text-transform: uppercase;
}

.text-small {
  font-size: 1.4rem;
}

.text-smaller {
  font-size: 1.2rem;
}

.text-big {
  font-size: 1.8rem;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-right {
  text-align: right !important;
}

.text-black {
  color: $color-black !important;
}
.text-grey {
  color: $color-grey;
}

.bg-white {
  background-color: $color-white;
  padding-top: 4rem;
  padding-bottom: 3rem;
}

.bg-grey {
  background-color: $bg-color;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.bg-aqua-lighter {
  background-color: $color-aqua-lighter;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.bg-light-grey {
  background-color: $bg-color-light;
  padding-top: 4rem;
  padding-bottom: 3rem;
}

.separator {
  border-top: 1px solid $color-lightblue;
}

.d-flex {
  display: flex !important;
}

.d-justify-center {
  justify-content: center !important;
}

.d-justify-right {
  justify-content: flex-end !important;
}
