// document type colours
$types: (
          "to_be_reviewed": $color-revisioned,
          "to_be_reviewed_accountant": $color-revisioned,
          "to_be_billed": $color-to-be-billed,
          "waiting_for_approval": $color-approval,
          "on_bill" : $color-approval,
          "billed": $color-billed,
          "partially_billed": $color-billed
        );

.billing-card {
  @each $name, $color in $types {
    &[data-status="#{$name}"] {
      .card-header {
        background: $color;
        border-radius: 12px 12px 0 0;
      }
    }
  }
}
