.service-header {
  padding-top: .5rem;
  padding-bottom: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .js-clear-filter {
    font-size: 1.4rem !important;
    margin: 0 .5rem;
  }

  .filter-trigger {
    border: 1px solid $input-border;
    border-radius: .6rem;
    padding: .9rem 1.2rem;
    font-size: 1.6rem;
    height: 4rem;
    color: $color-black;
    transition: border-color linear 0.15s, box-shadow linear 0.06s;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: $font-light;
    font-style: italic;

    .ojm-filters {
      color: $color-steel;
      font-size: 2rem;
      position: relative;
      top: 3px;
      transition: color linear 0.15s;
    }

    .filter-number {
      display: none;
      background: $color-petrol;
      font-size: 1.1rem;
      color: white;
      border-radius: 50%;
      padding: 3px;
      width: 21px;
      text-align: center;
      margin-left: 5px;
      position: relative;
      top: -2px;
    }

    &:hover {
      border-color: darken($input-border, 18%);
      .ojm-filters {
        color: $color-grey;
      }
    }

    &.is-open {
      border-color: $color-ojm;
      box-shadow: 0 0 0 1px $color-ojm;
      .ojm-filters {
        color: $color-grey;
      }
    }

    &.has-filters {
      font-weight: $font-normal;
      font-style: initial;
      .ojm-filters {
        color: $color-aqua !important;
      }
      .filter-number {
        display: inline-block;
      }
    }
  }

  .advance-search-overlay {
    display: none;
    position: absolute;
    right: 0;
    top: 5.5rem;
    z-index: 1;
    min-width: 60rem;
    .advance-search-wrapper {
      padding: 2rem 1.5rem;
      border: solid 1px $color-steel;
      background-color: $color-white;
      position: relative;
      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        right: 1.5rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9px 10px 9px;
      }
      &:before {
        border-color: transparent transparent $color-steel transparent;
        top: -1.1rem;
      }
      &:after {
        border-color: transparent transparent $color-white transparent;
        top: -.9rem;
      }

      .advance-search-section {
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $color-lightblue;
        .form-group {
          margin: 0 .5rem 1.5rem;

          input.form-control:not([type=search]), .bootstrap-select .dropdown-toggle {
            height: 3rem;
            font-size: 1.4rem;
            padding: 0.5rem 1.2rem;
          }
          input.form-control:not([type=search]) {
            text-overflow: ellipsis;
            font-weight: $font-semibold;
          }
          .bootstrap-select .dropdown-toggle {
            .caret {
              top: 7px;
            }
            .filter-option {
              padding-right: 1.5rem;
              font-weight: $font-semibold;
              .filter-option-inner-inner {
                text-overflow: ellipsis;
                // font-style: normal;
                // font-weight: normal;
              }
            }
          }
        }
        &:first-child {
          display: flex;
          flex-wrap: wrap;
          .form-group {
            flex: 0 0 calc(33% - 1rem);
            max-width: calc(33% - 1rem);
            margin: 0 .5rem 2rem;
          }
        }
      }
    }
  }

  form {
    width: 100%;
    .filter-flex-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .filter-wrapper {
      width: 18%;
      position: relative;
    }

    .form-search-wrapper {
      flex-grow: 1;
      max-width: 80%;

      .form-search {
        position: relative;

        &:before {
          display: block;
          content: $ojm-search;
          font-family: $icomoon-font-family;
          font-size: 2rem;
          color: $color-primary;
          position: absolute;
          left: 15px;
          top: 5px;
        }
        .btn-reset-search {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          display: none;
          align-items: center;
          justify-content: center;
          height: 4rem;
          width: 4rem;
          border-radius: 0 .6rem .6rem 0;
          cursor: pointer;
          font-size: 2.5rem;
          color: $color-aqua;
          &:hover {
            color: $color-petrol;
          }
        }
        input {
          padding-left: 4.5rem;
        }
      }
    }

    .filter-pills {
      margin-top: 2rem;
      .show-others {
        text-transform: uppercase;
        color: $color-primary;
        font-size: 1.2rem;
        text-decoration: underline;
        &:hover {
          color: $color-secondary;
        }
      }
    }
  }
}
