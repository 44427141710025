@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-dual-ring {
  background: transparent;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -2rem 0 0 -2rem;
  z-index: 10;
  &.is-fixed {
    position: fixed;
    left: calc(50% + 12rem);
    @media screen and (min-width: 1408px) {
      left: 82rem;
    }
  }
}

.lds-dual-ring:after {
  content: "";
  display: block;
  width: 3rem;
  height: 3rem;
  margin: .5rem;
  border-radius: 50%;
  border: 3px solid $color-secondary;
  border-color: $color-secondary transparent $color-secondary transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  .btn-primary & {
    border-color: $color-white transparent $color-white transparent;
  }
}

button[type="submit"].loading {
  color: transparent !important;
  cursor: default !important;
  pointer-events: none;

  .lds-dual-ring:after {
    border-color: $color-white transparent $color-white transparent;
  }
}


// page loader
.has-page-loader {
  position: relative;
}

.js-page-loader {
  background: rgb(234, 239, 244);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  &.stats-variant {
    opacity: .9;
    bottom: initial;
    height: 100%;
  }

  &.out {
    @include overlayAnimationHide;
  }
}
