@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

@import "_bootstrap.scss";
@import "~bootstrap-select/dist/css/bootstrap-select";

// Variables, Mixins and Components
@import "_loader.scss";
@import "utilities/all";
@import "theme/all";
@import "components/all";
@import "../fonts/ojm-docflow/style.scss";

@import "~izitoast/dist/css/iziToast";
@import "~izimodal/css/iziModal";
