.tooltip {
  .tooltip-inner {
    background: $color-petrol;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    text-transform: uppercase;
  }

  &.left .tooltip-arrow {
    border-left-color: $color-petrol;
    margin-top: -9px;
    border-width: 9px 0 10px 9px;
  }

  &.bottom .tooltip-arrow {
    border-bottom-color: $color-petrol;
    border-width: 0 10px 5px;
    margin-left: -10px;
  }

  &.top .tooltip-arrow {
    border-top-color: $color-petrol;
    border-width: 5px 10px 0;
    margin-left: -10px;
  }
}
