// Utilities
@import "gradient";
@import "inline-input-group";

%transition-in {
  transition: all .3s ease-in-out;
}

%transition-out {
  transition: all .15s ease-in-out;
}

@mixin link-line($line-color: $color-red, $dimension: 40%, $pos-left: 0) {
  position: relative;
  text-decoration: none;
  &:after {
    content: '';
    position: absolute;
    left: $pos-left;
    bottom: 0;
    height: 1px;
    width: 0;
    background: $line-color;
    @extend %transition-in;
    @if ($pos-left == 50%) {
      transform: translateX(-50%);
    }
  }
  &:hover:after {
    width: $dimension;
  }
}

/**
 ====================================================== CUSTOM ANIMATIONS
 */
$transition-ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$transition-ease: $transition-ease-out-quart;

@mixin overlayAnimation($activator, $visibility-target) {
  opacity: 0;
  transform: scale(1.2);
  transition: transform 0s $transition-ease-out-quart .55s, opacity .55s $transition-ease-out-quart;
  pointer-events: none;
  #{$visibility-target} {
    visibility: hidden;
    transition: visibility 0s linear .45s;
  }
  #{$activator} {
    opacity: 1;
    transform: scale(1);
    transition: transform .45s $transition-ease-out-quart, opacity .25s $transition-ease-out-quart .2s;
    pointer-events: auto;

    #{$visibility-target} {
      visibility: visible;
      transition: visibility 0s linear 0s;
    }
  }
}

@mixin overlayAnimationShow() {

}

@mixin overlayAnimationHide() {
  opacity: 0;
  // transform: scale(1.2);
  //transition: transform 0s $transition-ease-out-quart .55s, opacity .55s $transition-ease-out-quart;
  transition: opacity .55s $transition-ease-out-quart;
  pointer-events: none;
}
