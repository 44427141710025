.empty-block {
  padding-top: 5rem;
  padding-bottom: 3rem;
  text-align: center;

  i[class*='ojm'] {
    color: $color-secondary;
    font-size: 4rem;
  }

  img {
    max-width: 18rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
  }

  h3 {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
  }
}
