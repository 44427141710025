.switch-group {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: $font-light;
  color: $color-anthracite;
}

.switch-toggle {
  position: relative;
  display: block;
  width: 32px;
  height: 18px;
  margin-left: 1.5rem;

  input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-steel;
    border-radius: 9px;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: $color-aqua;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $color-aqua;
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }
}
