$status-map: (
  "waiting": $color-status-waiting,
  "waiting_for_leader": $quote-waiting-for-leader,
  "waiting_for_validator": $quote-waiting,
  "not_confirmed": $color-status-not-confirmed,
  "red": $color-status-not-confirmed,
  "not_confirmed_from_validator": $quote-not-confirmed,
  "not_confirmed_from_leader": $quote-not-confirmed,
  "to_be_reviewed_from_validator": $quote-to-be-reviewed,
  "to_be_reviewed_from_leader": $quote-to-be-reviewed,
  "not_good": $quote-draft,
  "approved": $color-status-confirmed,
  "green": $color-status-confirmed,
  "created": $color-secondary,
  "confirmed": $color-status-confirmed,
  "draft": $quote-nbf,
);


.alert {
  padding: 1.5rem 0;
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;

  p {
    margin-bottom: 0;
    font-size: 1.3rem;
    line-height: 1.35;
  }

  .alert-heading {
    margin-bottom: .5rem;
    font-size: 1.4rem;
    font-weight: $font-bold;
  }

  @each $name, $color in $status-map {
    &.alert--#{$name} {
      // background: mix(white, $color, 30%);
      background: lighten($color, 30%);
      border-color: $color;
      color: mix(black, $color, 50%);
      a {
        color: mix(black, $color, 50%);
        text-decoration: underline;
        transition: .34s ease text-decoration;
        font-weight: $font-semibold;
        &:active,
        &:focus,
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.alert {
  &--approved,
  &--confirmed {
    .custom-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        text-decoration: none;
        color: $color-secondary;
        transition: color .45s ease, background-color .45s ease;
        &:active,
        &:focus,
        &:hover {
          color: $color-white;
          background-color: $color-secondary;
        }
      }
    }
  }
}

