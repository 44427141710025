@mixin removeLink {
  display: none !important;
  visibility: hidden !important;
  position: absolute;
  right: -9999rem;
  top: -9999rem;
}

.doc-card, .billing-card {
  border-radius: 12px;
  background: $color-white;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 0px 2px $color-aqua;
  }

  .card-header,
  .card-body {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    padding: 1.5rem;
  }
  .card-header {
    align-items: center;
    padding: 1.3rem 1.5rem;
    font-weight: $font-light;
    color: $color-anthracite;
    .status.status_label {
      margin-bottom: 0;
    }
    .department {
      text-transform: uppercase;
    }

    .doc_type_icon {
      display:     flex;
      align-items: center;
      font-size:   1.6rem;

      i {
        margin-right: .6rem;
        font-size:    2rem;
      }
    }
  }
  .card-body {
    position: relative;
  }

  &.doc-old:not([data-doc_type="free_quote"]) {
    background:rgba(255, 255, 255, 0.5);
    .card-block--big {
      .card-data {
        color: $color-grey;
      }
    }
  }

  .card-wrap {
    flex: 0 1 100%;
    & + .card-wrap {
      padding-left: 2rem;
    }
    &.card-wrap--index {
      flex: 0 0 33%;
    }
    &.card-wrap--action {
      flex: 0 1 38%;
      display: flex;
      justify-content: flex-end;
      white-space: nowrap;
    }
    &.card-wrap--vertical {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .card-label {
    font-size: 1.24rem;
    color: $color-anthracite;
    text-transform: uppercase;
    display: block;
  }

  .card-data {
    font-weight: $font-semibold;
  }

  .card-block--big {
    .card-data {
      color: $color-secondary;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .card-block--small {
    color: $color-anthracite;
    font-size: 1.2rem;
    .card-data, .author, .agency_name {
      margin: 0 .3rem;
    }
  }


  .card-block {
    & + .card-block {
      padding-top: 1rem;
    }
  }

  .card-wrap--action {
    .edit-menu {
      a {
        color: $color-aqua;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        height: 4rem;
        width: 4rem;
        text-align: center;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        margin-top: 1.5rem;

        &:hover {
          color: $color-petrol;
        }
      }

      a[href=""] {
        @include removeLink;
      }
    }
  }

  .d-flex.justify-content-between.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}
