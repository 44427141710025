

//input type date

input[type="date"] {
    max-width: 24rem;
    min-width: 18rem;
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
      width: 100%;
      opacity: 0;
    }
  }
  
  //input text with euro symbol
  
  input[type="text"].money-input {
    max-width: 15rem;
    text-align: right;
  }
