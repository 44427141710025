@import "variables";

@font-face {
  font-family:  '#{$icomoon-font-family}';
  src:          url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?yjvu28') format('truetype'),
                url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?yjvu28') format('woff'),
                url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?yjvu28##{$icomoon-font-family}') format('svg');
  font-weight:  normal;
  font-style:   normal;
  font-display: block;
}

[class^="ojm-"], [class*=" ojm-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family:             '#{$icomoon-font-family}' !important;
  speak:                   never;
  font-style:              normal;
  font-weight:             normal;
  font-variant:            normal;
  text-transform:          none;
  line-height:             1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing:  antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ojm-clipboard {
  &:before {
    content: $ojm-clipboard;
  }
}

.ojm-user {
  &:before {
    content: $ojm-user;
  }
}

.ojm-interface-user {
  &:before {
    content: $ojm-interface-user;
  }
}

.ojm-acconto1 {
  &:before {
    content: $ojm-acconto1;
  }
}

.ojm-acconto2 {
  &:before {
    content: $ojm-acconto2;
  }
}

.ojm-acconto3 {
  &:before {
    content: $ojm-acconto3;
  }
}

.ojm-action {
  &:before {
    content: $ojm-action;
  }
}

.ojm-wait {
  &:before {
    content: $ojm-wait;
  }
}

.ojm-file {
  &:before {
    content: $ojm-file;
  }
}

.ojm-Property-1invoice {
  &:before {
    content: $ojm-Property-1invoice;
  }
}

.ojm-upload {
  &:before {
    content: $ojm-upload;
  }
}

.ojm-stats {
  &:before {
    content: $ojm-stats;
  }
}

.ojm-filters {
  &:before {
    content: $ojm-filters;
  }
}

.ojm-framework_agreement-fill {
  &:before {
    content: $ojm-framework_agreement-fill;
  }
}

.ojm-quote-fill {
  &:before {
    content: $ojm-quote-fill;
  }
}

.ojm-mandate-fill {
  &:before {
    content: $ojm-quote-fill;
  }
}

.ojm-multiprofile-fill {
  &:before {
    content: $ojm-multiprofile-fill;
  }
}

.ojm-free_quote-fill {
  &:before {
    content: $ojm-free_quote-fill;
  }
}

.ojm-framework_agreement {
  &:before {
    content: $ojm-framework_agreement;
  }
}

.ojm-free_quote {
  &:before {
    content: $ojm-free_quote;
  }
}

.ojm-quote {
  &:before {
    content: $ojm-quote;
  }
}

.ojm-multiprofile {
  &:before {
    content: $ojm-multiprofile;
  }
}

.ojm-log {
  &:before {
    content: $ojm-log;
  }
}

.ojm-nbf {
  &:before {
    content: $ojm-nbf;
  }
}

.ojm-config {
  &:before {
    content: $ojm-config;
  }
}

.ojm-anagrafica {
  &:before {
    content: $ojm-anagrafica;
  }
}

.ojm-revoke {
  &:before {
    content: $ojm-revoke;
  }
}

.ojm-arrow-up {
  &:before {
    content: $ojm-arrow-up;
  }
}

.ojm-arrow-down {
  &:before {
    content: $ojm-arrow-down;
  }
}

.ojm-download {
  &:before {
    content: $ojm-download;
  }
}

.ojm-close {
  &:before {
    content: $ojm-close;
  }
}

.ojm-send {
  &:before {
    content: $ojm-send;
  }
}

.ojm-trash {
  &:before {
    content: $ojm-trash;
  }
}

.ojm-archive {
  &:before {
    content: $ojm-archive;
  }
}

.ojm-angle-bold-down {
  &:before {
    content: $ojm-angle-bold-down;
  }
}

.ojm-angle-bold-left {
  &:before {
    content: $ojm-angle-bold-left;
  }
}

.ojm-angle-bold-right {
  &:before {
    content: $ojm-angle-bold-right;
  }
}

.ojm-angle-bold-up {
  &:before {
    content: $ojm-angle-bold-up;
  }
}

.ojm-angle-down {
  &:before {
    content: $ojm-angle-down;
  }
}

.ojm-angle-left {
  &:before {
    content: $ojm-angle-left;
  }
}

.ojm-angle-right {
  &:before {
    content: $ojm-angle-right;
  }
}

.ojm-angle-up {
  &:before {
    content: $ojm-angle-up;
  }
}

.ojm-check {
  &:before {
    content: $ojm-check;
  }
}

.ojm-remove-circle {
  &:before {
    content: $ojm-remove-circle;
  }
}

.ojm-add-circle {
  &:before {
    content: $ojm-add-circle;
  }
}

.ojm-calendar {
  &:before {
    content: $ojm-calendar;
  }
}

.ojm-check-circle {
  &:before {
    content: $ojm-check-circle;
  }
}

.ojm-user1 {
  &:before {
    content: $ojm-user1;
  }
}

.ojm-edit {
  &:before {
    content: $ojm-edit;
  }
}

.ojm-list {
  &:before {
    content: $ojm-list;
  }
}

.ojm-search {
  &:before {
    content: $ojm-search;
  }
}

.ojm-angle-left-circle {
  &:before {
    content: $ojm-angle-left-circle;
  }
}

.ojm-dashboard {
  &:before {
    content: $ojm-dashboard;
  }
}

.ojm-logout {
  &:before {
    content: $ojm-logout;
  }
}

.ojm-plus {
  &:before {
    content: $ojm-plus;
  }
}

.ojm-property-invoice {
  &::before {
    content: $ojm-Property-1invoice;
  }
}
