div.iziToast {
  padding: 0!important;
  font-family: inherit;
  margin: 0;
  &:after {
    box-shadow: none;
  }
}
.iziToast-overlay {
  background-color: rgba(0, 0, 0, 0.2)!important;
}
.iziToast.iziQuestion {
  max-width: 68rem;
  > .iziToast-body {
    padding: 6rem 2rem 4rem 2rem!important;
    margin: 0!important;
    .iziToast-icon.ico-question {
      position: relative;
      left: auto;
      margin: 1rem auto;
      display: none;
      width: 5rem;
      height: 5rem;
    }
    .iziToast-texts {
      float: none;
      margin: 0!important;
      .iziToast-title {
        margin-bottom: 4rem;
      }
      .iziToast-title, .iziToast-message {
        display: block;
        float: none;
        text-align: center;
        font-weight: normal;
        color: $color-white;
      }
      .iziToast-title {
        font-size: 2.6rem;
        line-height: 1;
      }
      .iziToast-message {
        font-size: 1.6rem;
        line-height: 2.4rem;
        p {
          margin-bottom: 0;
        }
      }
    }

    .iziToast-buttons {
      float: none;
      margin-top: 4rem;
      margin-bottom: 0;
      text-align: center;
      .iziToast-buttons-child {
        margin-left: 2.8rem;
        margin-right: 2.8rem;
        padding: 1.3rem 2rem;
        border-radius: 2rem;
        font-size: 1.6rem;
        line-height: 1;
        font-weight: $font-semibold;
        border: none;
        transition: .45s ease background-color, .45s ease color;
      }
      .btn-no {
        background-color: transparent;
        color: transparentize($color-white, .2);
        &:active,
        &:focus,
        &:hover {
          color: $color-white;
        }
      }
      .btn-yes {
        background: transparentize($color-white, .2);
        color: $color-aqua;
        &:active,
        &:focus,
        &:hover {
          background: $color-white;
        }
      }
    }
  }

  &.iziQuestionRed {
    background: $color-red !important;
    .iziToast-buttons {
      .btn-yes {
        background: transparentize($color-white, .2);
        color: $color-red;
        &:hover {
          background: $color-white !important;
          color: $color-red;
        }
      }
    }
  }
}

.iziToast.iziToastRed {
  background: $color-red !important;
}
