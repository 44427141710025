.badge {
  color: $color-ojm;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 700;
  border-radius: 0;
  background-color: #e7f2f5;
  padding: .8rem 1.6rem;
  margin-top: .8rem;
  margin-right: 1.6rem;
  border-radius: .6rem;
}