$icomoon-font-family:          "ojm-docflow" !default;
$icomoon-font-path:            "fonts" !default;

$ojm-clipboard:                "\e92b";
$ojm-user:                     "\e92c";
$ojm-interface-user:           "\e92d";
$ojm-acconto1:                 "\e92e";
$ojm-acconto2:                 "\e92f";
$ojm-acconto3:                 "\e930";
$ojm-action:                   "\e931";
$ojm-wait:                     "\e932";
$ojm-file:                     "\e933";
$ojm-Property-1invoice:        "\e934";
$ojm-upload:                   "\e935";
$ojm-stats:                    "\e92a";
$ojm-filters:                  "\e929";
$ojm-framework_agreement-fill: "\e925";
$ojm-quote-fill:               "\e926";
$ojm-multiprofile-fill:        "\e927";
$ojm-free_quote-fill:          "\e928";
$ojm-framework_agreement:      "\e921";
$ojm-free_quote:               "\e922";
$ojm-quote:                    "\e923";
$ojm-multiprofile:             "\e924";
$ojm-log:                      "\e91e";
$ojm-nbf:                      "\e91f";
$ojm-config:                   "\e920";
$ojm-anagrafica:               "\e91d";
$ojm-revoke:                   "\e91c";
$ojm-arrow-up:                 "\e912";
$ojm-arrow-down:               "\e913";
$ojm-download:                 "\e901";
$ojm-close:                    "\e918";
$ojm-send:                     "\e919";
$ojm-trash:                    "\e91a";
$ojm-archive:                  "\e91b";
$ojm-angle-bold-down:          "\e914";
$ojm-angle-bold-left:          "\e915";
$ojm-angle-bold-right:         "\e916";
$ojm-angle-bold-up:            "\e917";
$ojm-angle-down:               "\e90e";
$ojm-angle-left:               "\e90f";
$ojm-angle-right:              "\e910";
$ojm-angle-up:                 "\e911";
$ojm-check:                    "\e905";
$ojm-remove-circle:            "\e906";
$ojm-add-circle:               "\e907";
$ojm-calendar:                 "\e908";
$ojm-check-circle:             "\e909";
$ojm-user1:                    "\e90a";
$ojm-edit:                     "\e90b";
$ojm-list:                     "\e90c";
$ojm-search:                   "\e90d";
$ojm-angle-left-circle:        "\e900";
$ojm-dashboard:                "\e902";
$ojm-logout:                   "\e903";
$ojm-plus:                     "\e904";
