/***************
  CHECKBOX
***************/

/* VARS */
$check-border-radius: .3rem;
$check-dim: 2.4rem;
$check-label-font: 1.5rem;
$check-content-font: 1.8rem;
$check-position: (($check-dim - $check-content-font) / 2);
$background-check: #ffffff;
$background-check-on: $color-secondary;
$color-checkbox: #4A4A4A;
$color-checkbox-text: #4a4a4a;
$color-checkbox-border: $color-steel;
$color-checkbox-border-on: $color-secondary;
$color-checkbox-disabled: #A0A0A0;
$color-checkbox-text-disabled: $color-checkbox-disabled;
$checked-ladda: .6rem;

/* RULES */
input[type="checkbox"], input[type="radio"] {
  + label {
    color: $color-checkbox-text;
    cursor: pointer;
    display: inline-block;
    font-size: $check-label-font;
    font-weight: normal;
    line-height: 1.5;
    height: auto;
    margin: 0;
    padding-top: .3em;
    padding-bottom: .16em;
    padding-left: $check-dim + 1.5rem;
    position: relative;
    text-align: left;
    text-transform: none;
    vertical-align: middle;
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
    &:before, &:after {
      content: '';
      left: 0;
      line-height: $check-dim;
      position: absolute;
      transition: border .25s, background-color .25s, width .2s .1s, height .2s .1s, top .2s .1s, left .2s .1s;
      z-index: 1;
    }

    &:before {
      content: '';
      border: none;
      height: 1.2rem;
      width: 1.2rem;
      margin: 0;
      position: absolute;
      top: 1rem;
      left: 0.6rem;
      text-align: center;
      z-index: 2;
      transition: all ease-in-out .2s;
      transform-origin: $check-dim/2 $check-content-font/2;
    }

    &:after {
      background: $background-check;
      border: 1px solid $color-checkbox-border;
      height: $check-dim;
      margin-top: 0;
      width: $check-dim;
      top: 0.4rem;
      z-index: 0;
      border-radius: $check-border-radius;
    }

    + span {
      display: inline-block;
      vertical-align: top;
    }
  }

  &:not(:checked), &:checked {
    position: absolute;
    left: -9999px;
  }

  &:not(:checked) + label {
    &:before {
      color: $color-checkbox;
    }
  }

  &:checked + label {
    &:before {
      color: $color-checkbox;
      content: "";
      background: $background-check-on;
    }

    &:after {
      border-color: $color-checkbox-border;
    }
  }

  &:disabled {
    &:not(:checked) + label {
      color: $color-checkbox-text-disabled;
      cursor: default;

      &:before {
        border: 2px solid transparent;
      }

      &:after {
        border-color: transparent;
        background: trasparentize($color-checkbox-disabled, .7);
      }
    }

    &:checked + label {
      cursor: default;

      &:before {
        color: $color-checkbox-disabled;
        content: "\f00c";
      }

      &:after {
        border-color: transparent;
        background: trasparentize($color-checkbox-disabled, .7);
      }
    }
  }

  &.right {
    + label {
      padding-left: 0;
      padding-right: $check-dim + $check-content-font/2;
      width: 100%;

      &:before, &:after {
        left: auto;
        right: 0;
      }
    }
  }

  &.center {
    + label {
      &:before {
        top: 50%;
        margin-top: -$check-dim/2;
      }

      &:after {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

input[type="radio"] {
  + label {
    &:before, &:after {
      border-radius: 50%;
    }
  }
}

input[type="checkbox"] {
  & + label:before {
    font-family: '#{$icomoon-font-family}' !important;
    content: $ojm-check;
    height: auto;
    width: auto;
    top: .5rem;
    left: .5rem;
    color: white !important;
  }
  &:checked + label:before {
    content: $ojm-check;
    color: $color-white;
    background: transparent;
  }

  &:checked + label:after {
    background: $background-check-on;
    border-color: $background-check-on;
  }

  &.error + label:after {
    border-color: $color-error;
  }
}
