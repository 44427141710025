.form-group {
  .uploaded {
    display: none;
  }

  &.attachment {
    .uploaded {
      display: block;

      .ojm-close {
        cursor: pointer;

        &:hover {
          color: $color-red;
        }
      }

      span {
        vertical-align: middle;
        display: inline-block;

        img {
          width: 3.2rem;
          height: auto;
        }
      }
    }
  }
}
