.nav.nav-tabs {
  border-color: $color-lightblue;
  margin-bottom: 2rem;
  li {
    outline: none;
  }
  li > a,
  li > a:hover,
  li > a:focus {
    background: none;
    border: none;
    color: $color-grey;
    margin: 0;
    padding: 2rem 5rem;
    outline: none;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      right: 50%;
      bottom: -1px;
      height: 2px;
      background: $color-primary;
      transition: all 0.3s ease-in-out;
    }
  }
  li > a:hover,
  li > a:focus {
    color: $color-primary;
  }
  li.active > a {
    color: $color-grey;
    font-weight: $font-bold;
    &:after {
      left: 0;
      right: 0;
    }
  }
}

// white variant
.nav.nav-tabs-white {
  display: flex;
  margin: 0 1.5rem;
  li {
    outline: none;
    margin-right: 0.2rem;
    & > a,
    & > a:hover,
    & > a:focus {
      background: transparent;
      border: none;
      color: $color-aqua;
      text-transform: uppercase;
      font-size: 1.4rem;
      margin: 0;
      padding: 1rem 5rem;
      outline: none;
      border-radius: 1rem 1rem 0 0;
      transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    }
    & > a:hover {
      background: transparentize($color-white, .5);
    }
    &.active > a {
      background: $color-white;
      font-weight: $font-bold;
    }
  }
}
