.show-data {
  &--section {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid $color-lightblue;

    &:last-child {
      border-bottom: none;
    }

    label {
      margin: 0;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: $font-normal;
      color: $color-anthracite;
    }

    p {
      font-size: 1.6rem;
      margin: .8rem 0 0;
      line-height: 1.1;
    }

    .row + .row {
      margin-top: 3rem;
    }

    &.main-info {
      p {
        font-size: 1.8rem;
        font-weight: normal;
      }
    }
  }
}
