
// general label
label {
  color: $color-anthracite;
  font-size: 1.2rem;
  font-weight: $font-normal;
  text-transform: uppercase;
  margin-bottom: .5rem;
  form & {
    margin-bottom: .2rem;
  }
}

label.error,
.invalid-feedback,
.invalid-feedback strong {
  font-size: 1.2rem;
  font-weight: $font-normal;
  color: $color-error;
  text-transform: none;
  .form-control + & {
    margin: 1rem 0 -1.5rem;
  }
}

.invalid-feedback {
  display: block;
  width: 100%;
}

button,
input {
  outline: none;
}

@import "forms/_attachments.scss";
@import "forms/_checkboxes_and_radio.scss";
@import "forms/_switches.scss";


// inputs
.form-control {
  border: 1px solid $input-border;
  border-radius: .6rem;
  padding: .9rem 1.2rem;
  box-shadow: none;
  background: $color-white;
  font-size: 1.6rem;
  height: 4rem;
  color: $color-black;
  transition: border-color linear 0.15s, box-shadow linear 0.06s;

  &:hover {
    border-color: darken($input-border, 18%);
  }

  &:focus {
    border-color: $color-ojm;
    box-shadow: 0 0 0 1px $color-ojm;
  }

  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: $color-grey;
    font-style: italic;
    font-weight: $font-light;
  }

  &:read-only {
    border: none;
    padding: 0;
    background: none;
    outline: none !important;
    box-shadow: none;
  }

  &.error {
    border-color: $color-error !important;
    & + .note-frame {
      border-color: $color-error;
    }
    &:focus {
      transition: border-color linear 0.15s, box-shadow linear 0.06s;
      border-color: $color-error;
      box-shadow: 0 0 0 1px $color-error !important;
    }
  }
}

.form-control--inverse-error{
  .form-control.bootstrap-select.js--select{
    display: flex;
    flex-direction: column-reverse;
  }
}

textarea {
  min-height: 12rem;
  resize: none;
}

// select
.bootstrap-select {
  .dropdown-toggle {
    border: 1px solid $input-border;
    border-radius: .6rem;
    background: $color-white;
    transition: border-color linear 0.15s, box-shadow linear 0.06s;
    &:hover {
      border-color: darken($input-border, 18%);
    }
    &:focus {
      outline: none !important;
    }
    &.error {
      border-color: $color-error;
    }
    &.bs-placeholder {
      &, &:hover, &:focus, &:active {
        color: $color-grey;
        font-style: italic;
        font-weight: $font-light;
      }
    }
    .caret {
      width: auto;
      height: auto;
      border: none;
      top: 11px;
      right: 10px;
      &:before {
        font-family: '#{$icomoon-font-family}' !important;
        content: $ojm-angle-bold-down;
        font-size: 1.2rem;
        font-style: normal;
      }
    }
  }
  .dropdown-menu {
    border-color: $input-border;
    max-height: 30rem;
    & > li > a {
      padding: .9rem 1rem;
      outline: none;
      &:hover {
        background-color: $bg-color;
      }
    }
    & > li.disabled {
      font-weight: $font-light;
      font-style: italic;
    }
    & > .active > a,
    & > .active > a:hover,
    & > .selected > a,
    & > .selected > a:hover {
      background-color: darken($color-lightblue, 5%);
      color: $color-black;
    }

    .selected span.check-mark {
      top: 1.1rem !important;
      right: 1rem !important;
    }

    input.form-control {
      border: none;
      border-bottom: 1px solid $input-border;
      border-radius: 0;
      font-size: 1.4rem;
      padding: .5rem;
      height: auto;
      margin-bottom: 1rem;
      &:focus {
        border-color: darken($input-border, 18%);
        box-shadow: none;
      }
    }
  }
  &.open > .dropdown-toggle {
    &,
    &:hover,
    &:focus,
    &.focus {
      background: $color-white;
      border-color: $color-ojm;
      box-shadow: 0 0 0 1px $color-ojm;
    }
  }
  .no-results {
    padding: 1rem;
    white-space: normal;
    font-size: 1.2rem;
    text-align: center;
  }
}

// wrappers and non-inputs
.form-group {
  margin-bottom: 3.5rem;
  position: relative;
}

.form-item {
  font-size: 1.8rem;
  margin-bottom: 3.5rem;
}

// input group
.input-group {
  display: flex;
  label + .form-control:not(:first-child):not(:last-child) {
    border-radius: .6rem 0 0 .6rem;
  }
  .form-control:not(:last-child) {
    border-right: none;
  }
  .input-group-addon,
  .input-group-btn,
  .form-control {
    display: block;
    float: none;
    position: static;
  }
  .form-control {
    clip-path: inset(-1px 0px -1px -1px);
    &:hover {
      & + .input-group-addon {
        border-color: darken($input-border, 18%);
      }
    }
    &:focus {
      & + .input-group-addon {
        border-color: $color-ojm;
        box-shadow: 0 0 0 1px $color-ojm;
      }
    }
  }
  .input-group-addon {
    width: 4rem;
    padding: 1.1rem;
    color: $color-ojm;
    cursor: pointer;
    transition: border-color linear 0.15s, box-shadow linear 0.06s;
    &:last-child {
      background: $color-white;
      border-radius: 0 .6rem .6rem 0;
    }
  }
  .form-control.error + .input-group-addon {
    border-color: $color-error;
  }
}


// filepond
.form-control.filepond--root {
  border: none;
  min-height: 10rem;

  .filepond--panel-root {
    background: $color-white;
    min-height: 10rem;
  }
  .filepond--drop-label {
    min-height: 10rem;
  }
  .filepond--drop-label.filepond--drop-label label {
    font-weight: normal;
    text-transform: none;
    font-size: 1.4rem;
    .filepond--label-action {
      font-weight: $font-semibold;
      color: $color-aqua;
      &:hover {
        color: $color-petrol;
      }
    }
  }
}

.form-actions {
  margin-top: 3rem;
  margin-bottom: 3rem;
}


