.referent-list {
  .app-header {
    align-items: center;
    padding-top: 0;
    h2 {
      margin: 0;
    }
  }

  .referent--item {
    background-color: $color-white;
    padding: 1.5rem 1.5rem 0;
    margin-bottom: 1.5rem;
    border-radius: 1rem;

    p {
      font-size: 1.6rem;
      line-height: 1.1;
      margin-bottom: 1.5rem;
    }

    .service-tools {
      margin: 0;
      padding: 0;

      .edit-link,
      .add-link {
        color: $color-aqua;
        transition: opacity 0.3s ease-in-out;
        text-decoration: none;
        &:hover {
          color: $color-petrol;
        }
      }

      .edit-link {
        font-size: 1.8rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        width: 3rem;
        text-align: center;
      }

      .add-link {
        font-size: 1.6rem;
        margin-top: 2.7rem;
        display: block;
        font-weight: $font-bold;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}
