.template-message {
  padding: 2rem;
  background: $bg-color;
  border-radius: 1rem;

  h3 {
    margin: 0 0 1rem;
  }

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-size: 1.5rem;

    .text {
      width: 100%;
      p {
        font-weight: $font-semibold;
        margin-bottom: 0;
      }
    }

    .date {
      margin-left: 1.5rem;
      white-space: nowrap;
      color: $color-grey;
    }
  }

  &--content {
    margin-top: .8rem;
    padding: .5rem 0 3rem;
    border-top: 1px solid $color-lightblue;

    p {
      margin: 0;
      font-weight: $font-light;
      font-size: 1.6rem;
    }
  }

  .js--open-all {
    margin-top: 2rem;
    text-align: right;
    font-weight: bold;
    text-transform: uppercase;
    color: $color-aqua;
    transition: 0.35s ease color;
    cursor: pointer;
    &:hover {
      color: $color-petrol;
    }

    .t-open {
      &:after {
        content: $ojm-angle-bold-down;
        font-family: "ojm-docflow";
        font-weight: bold;
        font-size: 1.3rem;
        line-height: 1;
        margin-left: 0.5rem;
      }
    }

    .t-close {
      display: none;

      &:after {
        content: $ojm-angle-bold-up;
        font-family: "ojm-docflow";
        font-weight: bold;
        font-size: 1.3rem;
        line-height: 1;
        margin-left: 0.5rem;
      }
    }

    &.opened {
      .t-open {
        display: none;
      }

      .t-close {
        display: block;
      }
    }
  }

  &.template-message-negative {
    background: #e7cdd1;
    .template-message--content {
      border-color: $color-steel;
      padding-bottom: 0;
    }
  }

  &.template-message-alert {
    background: #fce6cd;
    .template-message--content {
      border-color: $color-steel;
      padding-bottom: 0;
    }
  }

  &.template-message-waiting {
    background: #b3ccd9;
    .template-message--content {
      border-color: $color-grey;
      padding-bottom: 0;
    }
  }

  &.template-message-positive {
    background: #ecf4da;
    .template-message--content {
      border-color: $color-steel;
      padding-bottom: 0;
    }

    .small {
      padding-top: 0;
      border-top: 0;

      a {
        color: $color-primary;
        text-decoration: none !important;
        font-size: 1.6rem;

        i {
          font-size: 1.6rem;
          font-weight: bold;
        }

        &:hover {
          color: $color-secondary;
        }
      }
    }

    .small + .template-message--header {
      margin-top: 2rem;
    }
  }
}
