aside {
  background: $color-aqua;
  padding: 3rem 0;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 24rem;
  z-index: 3;
  overflow-y: auto;

  .navbar-brand {
    &, & span, & em {
      color: $color-white;
    }
  }

  .navbar-brand,
  .user-card {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .user-warning {
    margin-top: 2rem;
    margin-bottom: 0;
    background: $color-red;
    color: $color-white;
    text-align: center;
    border-radius: 1.2rem;
    line-height: 1.2;
    padding: 1rem;
    font-size: 1.2rem;
  }

  ul.main-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    li {
      position: relative;
      margin-right: 2rem;
      margin-left: -4px;
    }

    li + li {
      margin-top: .5rem;
    }

    a {
      color: $color-white;
      display: block;
      font-size: 1.4rem;
      font-weight: $font-normal;
      padding: 1rem 1.5rem 1rem 3rem;
      border-radius: 0 2rem 2rem 0;
      text-decoration: none;
      text-transform: uppercase;
      transition: background .25s ease-in-out, font-weight .25s ease-in-out;

      i {
        font-size: 1.9rem;
        margin-right: .5rem;
        vertical-align: text-top;
      }

      &:hover {
        background: transparentize($color-petrol, 0.5);
      }

      &.active {
        cursor: default;
        font-weight: $font-semibold;
        background: transparentize($color-white, .9);
      }
    }
  }
}
