// document type colours
$types: (
          "document": $color-document,
          "quote": $color-quote,
          "mandate": $color-quote,
          "framework_agreement": $color-framework-agreement,
          "multiprofile": $color-multiprofile,
          "free_quote": $color-free-quote,
        );

@each $name, $color in $types {
  .ojm-#{$name}-fill {
    color: #{$color};
  }
}

.doc-card {
  @each $name, $color in $types {
    &[data-doc_type="#{$name}"] {
      .card-header {
        background:    transparentize($color, .9);
        border-radius: 12px 12px 0 0;
      }
    }
  }
}
