#footer {
  img {
    max-width: 180px;
    width: 100%;
    margin-left: 0;
  }

  .copy {
    color: $color-grey;
    font-size: 1.2rem;
    text-align: right;
  }
}
