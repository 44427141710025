%pill {
  display: inline-block;
  background: $color-status-waiting;
  border-radius: 22px;
  border: 1px solid transparent;
  color: $color-white;
  font-size: 1.3rem;
  font-weight: $font-bold;
  padding: 0.2rem 1rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 1408px) {
    white-space: nowrap;
  }

}

$status-with-bg: (
  "waiting": $color-status-waiting,
  "waiting_for_leader": $quote-waiting-for-leader,
  "waiting_for_validator": $quote-waiting,
  "not_confirmed": $color-status-not-confirmed,
                   "red": $color-status-not-confirmed,
                   "not_confirmed_from_validator": $quote-not-confirmed,
                   "not_confirmed_from_leader": $quote-not-confirmed,
                   "to_be_reviewed_from_validator": $quote-to-be-reviewed,
                   "to_be_reviewed_from_leader": $quote-to-be-reviewed,
                   "not_good": $quote-nbf,
                   "approved": $color-status-confirmed,
                   "green": $color-status-confirmed,
                   "created": $color-secondary,
                   "to_be_reviewed": $color-warning,
                   "to_be_reviewed_accountant": $color-warning,
                   "to_be_billed" : $color-violet,
                   "billing": $color-status-waiting,
                   "waiting_for_approval": $color-status-waiting,
                   "billed": $color-success
                 );
$status-with-border: (
  "confirmed": $color-status-confirmed,
  "draft": $quote-draft,
  "partially_billed": $color-success
);

.status-pill {
  @extend %pill;

  @each $name, $color in $status-with-bg {
    &.status-pill--#{$name} {
      background: #{$color};
    }
  }

  @each $name, $color in $status-with-border {
    &.status-pill--#{$name} {
      background: $color-white;
      color: #{$color};
      border-color: #{$color};
    }
  }
}

.doc-card, .billing-card {
  .status {
    @extend %pill;
  }

  @each $name, $color in $status-with-bg {
    &[data-status="#{$name}"] {
      .status {
        background: #{$color};
      }
    }
  }

  @each $name, $color in $status-with-border {
    &[data-status="#{$name}"] {
      .status {
        background: $color-white;
        color: #{$color};
        border-color: #{$color};
      }
    }
  }

  &[data-status="waiting"],
  &[data-status="not_confirmed"],
  &[data-status="not_confirmed_from_validator"] {
    .card-wrap--action .link_download {
      @include removeLink;
    }
  }
  &[data-status="partially_billed"] {
    .card-header {
      .status {
        border: 1px solid $color-success;
      }
    }
  }
}
.billing-card {
  .card-header {
    .status {
      border: none;
    }

  }
}



// filter pills

$status-filters: (
  "waiting": $color-status-waiting,
  "not_confirmed": $color-status-not-confirmed,
  "to_be_reviewed": $quote-to-be-reviewed,
  "not_good": $quote-nbf,
  "approved": $color-status-confirmed,
  "created": $color-secondary,
  "confirmed": $color-status-confirmed,
  "draft": $quote-draft,
  "to_be_billed" : $color-violet,
  "billing": $color-status-waiting,
  "waiting_for_approval": $color-status-waiting,
  "billed": $color-success,
  "on_bill": $color-status-waiting
);

.filter-pill {
  @extend %pill;
  background: transparent;
  border-color: $color-lightblue;
  color: $color-grey;
  font-weight: $font-semibold;
  padding: .4rem .4rem .4rem 1rem;
  margin-right: .8rem;
  margin-bottom: 1.2rem;
  transition: background 0.3s ease-in-out;

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    color: $color-white;
    margin-left: .6rem;
    font-size: 1.1rem;
    min-width: 2rem;
    padding: 0 .5rem;
    height: 2rem;
    background: #333;
  }

  &:hover {
    background: #f5f5f5;
  }

  &.active {
    background: $color-lightblue;
  }

  @each $name, $color in $status-filters {
    &.filter-pill--#{$name} span {
      background: #{$color};
    }
  }
  &.filter-pill--partially_billed {
    span {
      color: $color-success;
      background: transparent;
      border: 1px solid $color-success;
    }
  }
}
