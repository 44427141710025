.app-header {
  padding-top: 3rem;
  padding-bottom: 3rem;

  h1,
  .h1 {
    margin: 0 !important;
    .section {
      color: $color-ojm;
      font-weight: $font-normal;
      display: block;
      font-size: 1.4rem;
      text-transform: uppercase;
      margin-bottom: .5rem;
    }
  }

  &.header-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

// @todo: sistemare questa cosa
.header-secondary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 5rem;

  h2,
  .h2 {
    font-size: 3rem;
    font-weight: $font-light;
    color: $color-primary;
    line-height: 1.3;
    margin: 0;
  }
}
